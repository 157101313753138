<!-- 
 * @Author: 张阳帅
 * @Date: 2024-10-10 17:52:37
 * @LastEditTime: 2024-11-12 15:29:31
 * @LastEditors: Please set LastEditors
-->

<template>
  <div class="pd_page">
    <div class="page_top">
      <van-icon v-if="detail.zfzt == '2' " name="checked" color="#fff" size="80" />
      <van-icon v-else-if="detail.zfzt == '-3' " name="close" color="#fff" size="80" />
      <van-icon v-else name="clock-o" color="#fff" size="80" />
      <div class="page_text">
        <a>{{ detail.zfztmc || '支付中' }}</a>
      </div>
    </div> 
    <div v-if="detail.zfzt=='2'" class="content">
      <div class="con">
        <div class="item-left">
          收款机构： {{ detail.skjg }}
        </div>
        <div class="item-left">
          支付项目： {{ detail.ddlxmc }}
        </div>
        <div class="item-left">
          支付金额： ￥{{ detail.zfje }}
        </div>
        <div class="item-left">
          支付状态：<span style="color: #1485ff;">{{ detail.zfztmc }}</span>
        </div>
      </div>

      <div class="conTWo">
        <div class="item-left">
          流水号：
        </div>
        <div class="item-left">
          订单号：{{ detail.ddh }}
        </div>
        <div class="item-left">
          创建时间：{{ detail.createTime }}
        </div>
        <!-- <div class="item-left">
          支付状态：<span style="color: #1485ff;">{{ detail.zfztmc }}</span>
        </div> -->
      </div>
    
      <div class="tips">
        <!-- <div class="tips_text">
          温馨提示：
        </div> -->
        <span v-html="hospInfo.content" />
      </div>
    </div>
    <div class="page_btn">
      <van-button v-if="detail.ddlx=='3' && detail.qdpd" type="primary" @click="goPayFree()">
        <van-icon name="bill" color="#fff" size="17" />
        去签到
      </van-button>
      <van-button type="primary" @click="goPayAgain">
        <van-icon name="balance-list" color="#fff" size="17" />
        查看缴费记录
      </van-button>
      <van-button type="primary" @click="goBackHome">
        <van-icon name="wap-home" color="#fff" size="18" />
        返回首页
      </van-button>
    </div>
  </div>
</template>
<script>
import { gjcModel } from '../../../api/gjcApi'

export default {
  name:'PrescriptionDetail',
  data(){
    return{
      timer: null,
      remainingTime: 10, // 初始化倒计时为60秒
      detail:{},
      zfzt:'',
      hospInfo:{}
    }
  },
  mounted(){
    this.getOrderInfoOrderDetailData()
    this.gjcDepartDesc()
  },
  methods:{
    gjcDepartDesc(){
          gjcModel.gjcDepartDesc('ZFCG').then((result) => {
                this.hospInfo = result.data
            })
        },
    //查询订单详情
    getOrderInfoOrderDetailData(){
      gjcModel.ybOrderDetail(this.$route.query.orderId).then(res=>{
        console.log('查询订单详情', res.data)
        this.detail = res.data
        this.$set(this.detail,'wxts',this.detail.wxts.replace(/\n/g,"<br>"))
        //zfzt 支付状态 -1已取消 -2支付超时 -3 支付失败 -4退费失败 0待支付 1支付中 2支付完成 3退费中 4退费完成
        if (res.data.zfzt == '1'){
          this.timer = setInterval(()=>{
            this.getOrderInfoOrderDetailData()
            if (this.remainingTime > 0){
              this.remainingTime--
            }else{
              // 清除计时器
              if (this.timer) {
                clearInterval(this.timer)
              }
            }
          },1000)
        }else{
          // 清除计时器
          if (this.timer) {
            clearInterval(this.timer)
          }
        }
      })
    },

    goBackHome(){
      // this.$router.go(-1)
      this.$router.push('/')
      },
      goPayAgain(){
        this.$router.push({
        path: '/registerPayList',
        query: {
         type:this.detail.ddlx
        }
      }) 
      },
      goPayFree(){
      // this.$router.go(-1)
      this.$router.push({
        path: '/signIn'
      }) 
      }
  }
}
</script>
<style scoped lang='less'>
@import "../../../assets/styles/variable.less";
.pd_page{
  background-color: #f1f1f1;
  height: 100%;
  // padding: 15px;
  .page_top{
    // margin: 20px 20px;
    padding: 10px 10px 30px 10px;
    display: flex;
    // justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #48A9FE;
  }
  .page_text{
    font-size: 32px;
    color: #fff;
    width: 100%;
    text-align: center;
    padding-bottom: 60px;
  }
  .page_btn{
    position: fixed;
    bottom: 26px;
    display: flex;
    justify-content: space-around;
    width: 100%;
  }
  .content{
    padding: 30px ;
    padding-bottom: 200px;
    .con,.conTWo{
      background: #f8f8f8;
      border-radius: 12px;
      padding: 20px;
      font-size: 30px;
      line-height: 2;
    }
    .con{
      margin-bottom: 20px;
      margin-top: -60px;
    }
    .tips{
      font-size: 28px;
      line-height: 1.5;
      padding: 20px 20px 40px 20px;
    }
  }
  
}
</style>
